import * as React from "react";
import { Col , Row , CloseButton, Card, Button } from "react-bootstrap";
import { Compass } from 'react-bootstrap-icons';
import TourArt from "../../assets/tour.png"


function WelcomeBanner() {


	return (
		<Card className="p-4 shieldhub-welcome" data-bs-theme="dark">
			<div className="position-absolute closebtn">
				<CloseButton/>
			</div>
			<Row>
				<Col lg={6} sm={12}>
					<h2 className="display-6 banner-header mb-2">Welcome to ShieldHub</h2>
					<ul className="feature-bullets mt-3">
						<li>Search & verify ShieldIDs</li>
						<li>Purchase & share your background check results</li>
						<li>Build & manage a trusted network of contractors</li>
					</ul>
					<p className="mt-3"><b>Get started by purchasing a background check<br/>package below.</b></p>
					<Button variant="primary" size="lg" className="mt-2 p-3 button-tour" style={{fontSize: '18px', minWidth:'100%'}}><Compass style={{marginRight: '10px'}}/>Take a Tour!<img src={TourArt}  className="tour-plane"></img></Button>{' '}
				</Col>
			</Row>
		</Card>
	);
}

export default WelcomeBanner;