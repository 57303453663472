import * as React from 'react';
import { Modal , Row , Col, Button, Container , Form, FormControl , Spinner , Table } from 'react-bootstrap';
import { BoxArrowUpRight, CaretRightFill, Download, ShieldFillCheck, ShieldSlashFill } from 'react-bootstrap-icons';
import ICScore from '../ICScore';
import api from '../../io/profile';
import { useRefreshEmitter } from '../RefreshEmitter';
import {format as dateFormat , isValid as dateValid , parseJSON as dateParseJSON } from "date-fns";


function ScoreAcceptance( props )
{
	const emitter = useRefreshEmitter();

	const [ modalstep , setModalStep ] = React.useState(false);

	const [ pendingApplication , setPendingApplication ] = React.useState(false);
	const [ pendingInvites , setPendingInvites ] = React.useState([]);
	const [ profile , setProfile ] = React.useState({});

	const [ reviewaction , setReviewaction ] = React.useState(false);
	const [ reviewErrorText , setReviewErrorText ] = React.useState('');

	const [ dl_link_ip , setDl_link_ip ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloadlinkclicked , setDownloadLinkClicked ] = React.useState(false);

	async function LoadApplication( appid )
	{

		let response = await api.getpendingapplication( appid );
		if(response.success)
		{
			setProfile(response.profile);

			setPendingApplication(response.application);

			if(response.pendinginvites && response.pendinginvites.length > 0)
			{

				response.pendinginvites = response.pendinginvites.map( (invite) => {

					if(invite.invite_dts)
					{
						try{
							invite.invite_dts = dateParseJSON(invite.invite_dts);
						}
						catch
						{
							invite.invite_dts = null;
						}
					}

					return invite;
				});

			}


			setPendingInvites(response.pendinginvites);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{

			setPendingApplication({
				applicationid : 1000,
				cra_profile_id : '2024090111111111',
				scorechangetype : 'monitorupdate',
				currenticscore : 'IC01+',
				acceptterms : 'NO',
				acceptterms_dts : null,
			});

			setPendingInvites([
				{
					inviteid : 1,
					firstname:'First',
					lastname : 'Last',
					businessname:'Business',
					invite_dts:'06/01/2024',
					accepted : 'YES'
				}				
			]);

			setProfile({
				userid : 2000,
				emailaddress : 'test@example.com',
				network_autoacceptinvite : 'NO',
				network_searchresults : 'YES'
			});
		}
		
		setModalStep(1);
	}

	function UpdateAcceptTerms(event)
	{
		let app_object = { ...pendingApplication };

		app_object.acceptterms = (event.currentTarget.checked ? 'YES' : 'NO');

		setPendingApplication(app_object);
	}

	async function UpdateUserAction()
	{
		let app_object = { ...pendingApplication };

		app_object.useraction = reviewaction;

		setPendingApplication(app_object);

		let request = {
			applicationid : pendingApplication.applicationid,
			acceptterms : pendingApplication.acceptterms,
			downloadlinkclicked : downloadlinkclicked ? 'YES' : 'NO',
			reviewaction,
		};

		let response = await api.saveacceptdetails( request );
		if(response.success)
		{
			if(reviewaction === 'accept')
			{
				setModalStep(8);
			}
			else if(reviewaction === 'reporterror')
			{
				setModalStep(5);
			}
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			if(reviewaction === 'accept')
			{
				setModalStep(8);
			}
			else if(reviewaction === 'reporterror')
			{
				setModalStep(5);
			}
		}
	}

	function handleActionChange( action )
	{
		setReviewaction(action);
	}

	function UpdateProfile( field , value )
	{
		let profile_obj = { ...profile };

		profile_obj[field] = ( value ? 'YES' : 'NO');

		setProfile(profile_obj);
	}

	function UpdateInviteAcceptance( inviteid , accept )
	{

		let invites = [ ...pendingInvites];

		invites = invites.map( (record) => {

			if(record.id === inviteid)
			{
				record.accepted = ( accept ? 'YES' : 'NO');
			}

			return record;
		});

		setPendingInvites(invites);
	}

	async function GetDownloadLink()
	{
		let response = await api.getDownloadLink( pendingApplication.applicationid );
		if(response.success)
		{
			setDownloadlink(response.downloadlink);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setDownloadlink('http://www.google.com');
		}

		setDl_link_ip(false);
	}

	function UpdateReviewError( event )
	{

		setReviewErrorText(event.currentTarget.value);

	}

	function ResetModals()
	{
		setModalStep(false);
		setReviewaction(false);
		setDl_link_ip(false);
		setDownloadlink(false);
		setDownloadLinkClicked(false);

		if(props.ResetOpenScoreAcceptanceModal)
		{
			props.ResetOpenScoreAcceptanceModal();
		}

		emitter.Emit('globalrefresh');
	}

	async function SaveErrorDetails()
	{
		let request = {
			applicationid : pendingApplication.applicationid,
			details : 'cra_error_request',
			downloadlinkclicked : downloadlinkclicked ? 'YES' : 'NO',
			errortext : reviewErrorText
		};

		let response = await api.saveacceptdetails( request );
		if(response.success)
		{
			setModalStep(6);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setModalStep(6);
		}
	}

	async function SaveSharingPreferences()
	{
		let request = {
			details : 'update_sharing',
			network_autoacceptinvite : (profile.network_autoacceptinvite === 'YES' ? 'YES' : 'NO'),
			network_searchresults : (profile.network_searchresults === 'YES' ? 'YES' : 'NO'),
			pendingInvites,
		}

		let response = await api.saveacceptdetails( request );
		if(response.success)
		{
			ResetModals();
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			ResetModals();
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(()=>{

		if(props.ShowScoreAcceptModal !== false && parseInt(props.ShowScoreAcceptModal) > 0 && modalstep === false)
		{
			( async () => {
				await LoadApplication( props.ShowScoreAcceptModal );
			})();
				
		}
		else if(props.ShowScoreAcceptModal !== false && props.ShowScoreAcceptModal.match(/ASPN_[0-9]+\|[0-9]{16}/) && modalstep === false)
		{
			( async () => {
				await LoadApplication( props.ShowScoreAcceptModal );
			})();
				
		}

	},[props.ShowScoreAcceptModal]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			{/*  Welcome Modal */}
			<Modal size='lg' show={modalstep === 1} centered={true} onHide={ResetModals} animation={modalstep === 1}>
				<Modal.Header closeButton />
				<Modal.Body className='text-center'>
					<Row>
						<Col>
							<img src={`${process.env.PUBLIC_URL}/images/shieldscore-envelope.svg`} className="icon-125 mb-2" />
						</Col>
					</Row>
					{(pendingApplication.scorechangetype === null || pendingApplication.scorechangetype === undefined) && <Spinner />}
					{pendingApplication.scorechangetype === 'new' && 
						<Row className='pb-3 text-center'>
							<Col xs={{offset:2,span:8}}>
								<div style={{fontSize:'24px',fontWeight:'500'}}>We&apos;ve Got Your Score!</div>
								Please review and accept your background check to activate your ShieldScore.
							</Col>
						</Row>
					}
					{pendingApplication.scorechangetype === 'monitorupdate' && 
						<Row className='pb-3 text-center'>
							<Col xs={{offset:2,span:8}}>
								<div style={{fontSize:'24px',fontWeight:'500'}}>We&apos;ve Got Your Score!</div>
								Your ShieldScore has been temporarily expired, please review and accept your update to reactivate your ShieldScore.
							</Col>
						</Row>
					}
					<Row className='pb-5'>
						<Col>
							<Button variant='primary' onClick={()=>{
								setModalStep(2);
							}} >Get Started <CaretRightFill /> </Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
			{/*  End Welcome Modal */}
			{/*  Terms and Conditions Modal */}
			<Modal size='lg' show={modalstep === 2} centered={true} onHide={ResetModals} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Background Check Acceptable Use &amp; Terms</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container style={{border:'1px solid #CED4DA',borderRadius:'4px',overflowY:'scroll',height:'300px'}} className='p-3 mb-3'>
						<h4>BACKGROUND CHECK ACKNOWLEDGEMENT AND RELEASE AUTHORIZATION</h4>
						<p>I understand that the information that I requested is for use by myself and/or my work provider(s) and I hereby request that ClearStar makes such information available to ShieldHub in order to facilitate the creation of a ShieldID also formerly known as Assigned Background Compliance Number (&ldquo;ABC#&rdquo;) and expiration date for that ShieldID (&ldquo;Expiration Date&rdquo;).</p>
						<p>I also hereby consent and authorize the disclosure of the resulting ShieldID, Expiration Date, Individual Compliance score (&ldquo;IC&rdquo;) or (&ldquo;Score&rdquo;), legal name, and photo of me (where available), to my work provider(s), or ShieldHub to any person or entity that I or my work provider(s) are currently connected with through ShieldHub or which I or my work provider connect(s) with through ShieldHub at any time after the date of this written authorization and consent.</p>
						<p>I also hereby consent and authorize the disclosure of the resulting PDF Background Check Report to ShieldHub, and to my work provider(s) when required through ShieldHub at any time after the date of this written authorization and consent.</p>
						<p><b>BY SELECTING NO</b>, the Expiration Date and IC Score will not be assigned and therefore, your legal name, photo (where available), ShieldID, Score, and Expiration Date will:</p>
						<ol>
							<li>NOT be made available to any work provider you or to any person or entity that my work provider(s) are currently connected with through ShieldHub or my work provider connect(s) with through ShieldHub.</li>
							<li>If a Score and Expiration Date previously were released, the assigned ShieldID will be placed in &ldquo;Expired&rdquo; status.</li>
							<li>In the case of a work provider that you have previously made these details available to through ShieldHub, Aspen Grove Solutions, or other connected application, then no updates associated with this background check will be made available to them.</li>
							<li>The lack of a released Score and Expiration Date status may negatively impact the compliance determination for an assigned job by work provider(s).</li>
						</ol>
						<p><b>IF YOU DO NOT AGREE</b> with the information found on the background check, you have the right to dispute the accuracy or completeness of any information contained in the report by contacting the ClearStar Compliance team directly at disputes@clearstar.com or 877-275-7099.</p> 
					</Container>
					<Form.Check
						id='accept_terms_background'
						label={<Form.Check.Label role='button'>I agree to the acceptable use and terms of this background check</Form.Check.Label>}
						checked={pendingApplication.acceptterms === 'YES'}
						onChange={UpdateAcceptTerms}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" disabled={pendingApplication.acceptterms !== 'YES'} onClick={()=> {
						setModalStep(3);
					}}>Next <CaretRightFill /></Button>
				</Modal.Footer>
			</Modal>
			{/*  End Terms and Conditions Modal */}
			{/* Review score */}
			<Modal size='lg' show={modalstep === 3} centered={true} onHide={ResetModals} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Review &amp; Accept ShieldScore</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className='p-3'>
							<Col xs={6}>Your ShieldScore&trade;:</Col>
							<Col xs={6}>
								<ICScore score={pendingApplication.currenticscore} />
							</Col>
						</Row>
						<Row className='p-3'>
							<Col xs={6}>Download &amp; Review Report:</Col>
							<Col xs={6}>
								<Button variant='primary' onClick={async (event)=>{

									if(downloadlink === false)
									{
										setDl_link_ip(true);
										event.preventDefault();
										await GetDownloadLink();
									}

									if(dl_link_ip === false && downloadlink !== false )
									{
										setDownloadLinkClicked(true);
									}

								}} href={ downloadlink !== false ? downloadlink : null } >
									{ dl_link_ip === false && downloadlink === false && <><Download /> Download Report</>}
									{ dl_link_ip === true && downloadlink === false && <><Spinner size='sm' /> Please wait ...</>}
									{ dl_link_ip === false && downloadlink !== false && <><BoxArrowUpRight /> Open Report</>}
								</Button>
							</Col>
						</Row>
						<Row className='p-3'>
							<Col xs={12}>
								<Form.Check
									type='radio'
									id='reviewaction_accept'
									name='reviewaction'
									label={<Form.Check.Label role='button'>I accept my ShieldScore of <b>{pendingApplication.currenticscore}</b></Form.Check.Label>}
									onChange={() => {
										handleActionChange('accept');
									}}
								/> 
							</Col>
						</Row>
						<Row className='p-3'>
							<Col xs={12}>
								<Form.Check
									type='radio'
									id='reviewaction_reporterror'
									name='reviewaction'
									label={<Form.Check.Label role='button'>I do not accept my ShieldScore of <b>{pendingApplication.currenticscore}</b>. My report has errors that need to be corrected by the Consumer Reporting Agency (CRA)</Form.Check.Label>}
									onChange={() => {
										handleActionChange('reporterror');
									}}								
								/> 
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					{(reviewaction === false || reviewaction === 'accept') && <Button variant='primary' disabled={reviewaction === false} onClick={()=>{
						UpdateUserAction();
						
					}}><ShieldFillCheck /> Activate My ShieldScore</Button>}
					{ reviewaction === 'reporterror' && <Button variant='danger' onClick={() => {
						UpdateUserAction();
					}}>Report An Error <CaretRightFill /></Button>}
				</Modal.Footer>
			</Modal>
			{/* End Review Score */}
			{/* Report Error Modal */}
			<Modal size='lg' show={modalstep === 5} centered={true} onHide={ResetModals} enforceFocus={true} animation={false}>
				<Modal.Header>
					<Modal.Title>Review &amp; Accept ShieldScore</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>Your Consumer Reporting Agency (CRA) makes every effort to create an accurate report.</Col>
					</Row>
					<Row className='pb-3 pt-3' style={{borderBottom:'1px solid #DEE2E6'}}>
						<Col xs={4}><b>CRA Contact Information:</b></Col>
						<Col xs={6}>
							<b>ClearStar</b><br />
							disputes@clearstar.com<br />
							(877) 769-2559<br />
							<br />
							Order ID / Profile ID: {pendingApplication.cra_profile_id}
						</Col>
					</Row>
					<Row className='mt-4'>
						<Col xs={5}>
							<b>Submit Via Form</b><br />
							Please explain the error you need corrected in your report. Your information will be emailed to the CRA.<br />
							<br />
							A copy will be sent to your email {profile.emailaddress}. It is your responsibility as a candidate to resolve any errors or issues in your report.
						</Col>
						<Col xs={7}>
							<FormControl 
								style={{fontSize:'14px'}}
								as='textarea' 
								rows={8}
								value={reviewErrorText}
								placeholder='Explain the errors with your background check report'
								onChange={UpdateReviewError}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						variant='danger' 
						disabled={( !(reviewErrorText) || reviewErrorText === '' || reviewErrorText.length < 8)}
						onClick={SaveErrorDetails}
					><ShieldSlashFill /> Submit Error</Button>
				</Modal.Footer>
			</Modal>
			{/* End Report Error Modal */}
			{/* Start Correction Submitted Confirmation */}
			<Modal size='lg' show={modalstep === 6} centered={true} onHide={ResetModals} enforceFocus={true} animation={false}>
				<Modal.Header>
					<Modal.Title>Correction Submitted</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Your correction request was sent to the Consumer Reporting Agency of record. Their contact information is below. Your ShieldID will remain inactive until you accept a background check.<br />
					<br />
					<b>It is your responsibility as a candidate to work with ClearStar to resolve this issue:</b><br />
					<br />
					<Row>
						<Col xs={4}>CRA Contact Information:</Col>
						<Col xs={8}>
							<b>ClearStar</b><br />
							disputes@clearstar.com<br />
							(877) 796-2559<br />
							<br />
							Order ID / Profile ID : {pendingApplication.cra_profile_id}
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={ResetModals} >DONE</Button>
				</Modal.Footer>
			</Modal>
			{/* End Correction Submitted Confirmation */}
			{/* Start Invitation and Sharing */}
			<Modal size='lg' show={modalstep === 8} centered={true} onHide={ResetModals} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Invitations and Sharing Preferences</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{pendingInvites && pendingInvites.length > 0 &&
					<>
						<Row>
							<Col>
								<b>Pending Invitations</b><br />
								Companies below have invited you to join their trusted network to share your background check and ShieldScore. Select which network invitations you would like to accept and then click the accept button below.
							</Col>
						</Row>
						<Row>
							<Col className='px-5 py-2'>
								<Table>
									<thead>
										<th>Name</th>
										<th>Company</th>
										<th>Invitation Date</th>
										<th className="text-center">Enable</th>
									</thead>
									<tbody>
										{pendingInvites && pendingInvites.map((invite) => {

											return ( 
												<tr key={invite.id}>
													<td>{invite.firstname} {invite.lastname}</td>
													<td>{invite.businessname}</td>
													<td>{invite.invite_dts && dateValid(invite.invite_dts) ? dateFormat(invite.invite_dts,'MM/dd/yyyy') : null }</td>
													<td className="text-center">
														<Form.Check
															type='switch'
															style={{transform:"scale(1.3)"}}
															checked={invite.accepted === 'YES'}
															onChange={( event ) => {
																UpdateInviteAcceptance( invite.id , event.currentTarget.checked );
															}}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</Col>
						</Row>
					</>}
					<Row>
						<Col>
							<b>Network Preferences</b><br />
							Update your network preferences to make it easier for customers to find and invite you.
						</Col>
					</Row>
					<Row className='pt-4'>
						<Col sm={{offset:1,span:9}}>
							<b>Auto Accept Network Invites</b><br />
							Automatically accept network invites. Please be aware that automatically enabling this feature may allow you to share scores with unintended users.
						</Col>
						<Col xs={1}>
							<Form.Check
								type='switch'
								style={{transform:"scale(1.3)"}}
								checked={profile.network_autoacceptinvite === 'YES'}
								onChange={(event) => {

									UpdateProfile('network_autoacceptinvite',event.currentTarget.checked);

								}}
							/>
						</Col>
					</Row>
					<Row className='pt-4'>
						<Col sm={{offset:1,span:9}}>
							<b>ShieldID&trade; (ABC#) Search Results</b><br />
							Share your ShieldScore (IC#) results with users searching for your ShieldID (ABC#). Disabling this feature will make your profile unsearchable within the network.
						</Col>
						<Col xs={1}>
							<Form.Check
								type='switch'
								style={{transform:"scale(1.3)"}}
								checked={profile.network_searchresults === 'YES'}
								onChange={(event) => {
									UpdateProfile('network_searchresults',event.currentTarget.checked);
								}}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={SaveSharingPreferences}><ShieldFillCheck /> Share My ShieldScore</Button>
				</Modal.Footer>
			</Modal>
			{/* End Invitation and Sharing */}
		</>
	);

}
export default ScoreAcceptance;