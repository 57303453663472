import * as React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/user/header"
import LeftSidebar from "../components/user/LeftSidebar";
import { Container , Col , Row, Stack, Offcanvas, Button, ToastContainer, Toast, Spinner} from "react-bootstrap";
import WelcomeBanner from "../components/user/WelcomeBanner";
import SearchShieldID from "../components/user/SearchShieldID";
import InvitationTable from "../components/user/Invitations";
import { List } from 'react-bootstrap-icons';
import MyNetwork from "../components/user/MyNetwork";
import Profile from "../components/user/profile/ProfileInfo.js";
import PasswordAndSecurity from "../components/user/profile/PasswordAndSecurity";
import PrivacyAndSharing from "../components/user/profile/PrivacyAndSharing";
import BGCheckApplicationModal from "../components/user/profile/BGCheckApplicationModal";
import MyBackgroundChecks from "../components/user/MyBackgroundChecks";
import { useAuth } from "../components/Auth";
import AddtoNetworkModal from "../components/user/AddtoNetworkModal.js";
import AcceptNewTerms from "../components/user/AcceptNewTerms.js";
import UserEnterpriseDashboardEmbed from "../components/user/enterprise/UserEnterpriseDashboardEmbed.js";
import PurchaseBackgroundCheck from "../components/user/PurchaseBackgroundCheck.js";
import PurchaseWorkflow from "../components/user/PurchaseWorkflow.js";
import IDVerifyWorkflow from '../components/user/IDVerifyWorkflow.js';
import Purchases from "../components/user/profile/Purchases.js";
import Alerts from "../components/user/Alerts.js";
import ScoreAcceptanceModal from "../components/user/ScoreAcceptanceModal.js";
import Joyride from 'react-joyride';

function User() {

	let params = useParams();
	let auth = useAuth();
	const [show, setShow] = React.useState(false);

	const [ saveinprogress , setSaveInProgress ] = React.useState(false);
	const [ saveevents , setSaveEvents ] = React.useState({});

	const [ ShowScoreAcceptModal , setShowScoreAcceptModal ] = React.useState(false);

	function handleClose()
	{
		setShow(false);
	}
	function handleShow()
	{
		setShow(true);
	}

	function SaveEvent( component , event )
	{
		let newsaveevents = {...saveevents};

		if(event === 'startsave')
		{
			newsaveevents[component] = event;
		}
		else if(event === 'endsave')
		{
			delete newsaveevents[component];
		}

		if(Object.keys(newsaveevents).length > 0)
		{
			setSaveInProgress(true);
		}
		else
		{
			setTimeout(() => {
				setSaveInProgress(false);
			}, 2000);
		}

		setSaveEvents(newsaveevents);

	}

	function OpenScoreAcceptanceModal( appid )
	{

		setShowScoreAcceptModal( appid );

	}

	function ResetOpenScoreAcceptanceModal()
	{
		setShowScoreAcceptModal(false);
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if(auth?.user?.accepted_current_tc && auth?.user?.accepted_current_tc === 'true')
		{
			if(auth?.user?.pending_score_change && (parseInt(auth.user.pending_score_change) > 0 || auth.user.pending_score_change.match(/ASPN_[0-9]+\|[0-9]{16}/) ) )
			{
				setShowScoreAcceptModal( auth.user.pending_score_change );
			}
		}

	},[auth?.user?.accepted_current_tc,auth?.user?.pending_score_change]);
	React.useEffect(() => {

		if(window.clarity)
		{
			window.clarity('set','userid',auth?.user?.userid);
		}

	},[params.panel]);
	/* eslint-enablereact-hooks/exhaustive-deps */

	return (

		<>
			<Header />
			<Button className="mobile-menu d-block d-md-none" variant="link" onClick={handleShow}>
				<List/>
			</Button>
			<Container fluid style={{backgroundColor:"#e5e5e5",height:"100%"}}>
				<Row>
					<Col md={1} lg={2} className="l-sidebar">
						<Offcanvas show={show} onHide={handleClose} responsive="md">
							<Offcanvas.Header closeButton />
							<Offcanvas.Body>
								<LeftSidebar onClick={handleClose}/>
							</Offcanvas.Body>
						</Offcanvas>
					</Col>
					<Col md={11} lg={10}>
						{params.panel === undefined &&
							<Stack className="gap-3 pt-3 pb-3 ps-1">
								<SearchShieldID/>
								{ auth.enterpriseprofile && auth.enterpriseprofile.dashboard_enabled && auth.enterpriseprofile.dashboard_enabled === true ? <>
									<UserEnterpriseDashboardEmbed />
								</> : ( <>
									{ (!(auth?.user?.applicationcount) || auth?.user?.applicationcount === '0' ) && <>
										<WelcomeBanner/>
										<PurchaseBackgroundCheck />
									</>}
									{( parseInt(auth?.user?.applicationcount) > 0) && <>
										<MyBackgroundChecks OpenScoreAcceptMethod={OpenScoreAcceptanceModal} />
										<ScoreAcceptanceModal ShowScoreAcceptModal={ShowScoreAcceptModal} ResetOpenScoreAcceptanceModal={ResetOpenScoreAcceptanceModal}/>
									</>}
									<InvitationTable />
								</> )}
							</Stack>}
						{params.panel === "profile" &&
							<>
								<Stack className="gap-3 pt-3 pb-3 ps-1">
									<Profile SaveEvent={SaveEvent}/>
									<PasswordAndSecurity SaveEvent={SaveEvent}/>
									<PrivacyAndSharing SaveEvent={SaveEvent}/>
									<Purchases />
								</Stack>
								<ToastContainer position='bottom-center' style={{zIndex:1000}} className="position-fixed p-4">
									<Toast bg='primary' show={saveinprogress}>
										<Toast.Body style={{textAlign:'center',color:'#FFFFFF',fontWeight:'bold'}}>Saving Profile&nbsp;&nbsp;&nbsp;&nbsp;<Spinner as="span" size="sm" animation="border" />&nbsp;&nbsp;</Toast.Body>
									</Toast>
								</ToastContainer>
							</>}
						{params.panel === "network" && 
							<Stack className="gap-3 pt-3 pb-3 ps-1">
								<InvitationTable />
								<MyNetwork />
							</Stack>}
						{params.panel === 'purchase' && 
							<PurchaseWorkflow />
						}
						{params.panel === 'idverify' && 
							<IDVerifyWorkflow />
						}
						{params.panel === 'alerts' && 
							<Alerts />
						}
					</Col>
				</Row>
			</Container>
			<BGCheckApplicationModal />
			<AddtoNetworkModal />
			<AcceptNewTerms show={ ( auth && auth.user && auth.user.accepted_current_tc === 'false' ) }/>
			<div className="app">
				<Joyride
					continuous
					showProgress
					steps={[
						{
							target: ".search",
							content: <><h2>Search ShieldID&trade;</h2><p>Use this search feature to verify a person&apos;s ShieldScore by searching their provided ShieldID. Searching must be enabled on the users profile.</p></>  

						},
						{
							target: ".purchase-button",
							content:  <><h2>Purchase Background Check</h2><p>Click here to select from available packages and purchase a background check. You must complete a background check purchase before you can share your ShieldScore.</p></>,

						},						
						{
							target: ".invitations",
							content: <><h2>My Invitations</h2><p>Here are invitations to join a client&apos;s trusted network. By accepting the invite you are consenting to share your ShieldScore and other data with inviting user</p></>,
							placement:"auto"
						},
						{
							target: ".dashboard",
							content: <><h2>Dashboard Navigation</h2><p>Use this navigation item to return the start page.</p></>,
							placement:"right-start",
						},
						{
							target: ".profile",
							content: <><h2>Profile Navigation</h2><p>Use this navigation item to update your user profile, security and sharing preferences.</p></>,
							placement:"right-start",
						},
						{
							target: ".network",
							content: <><h2>Network Management Navigation</h2><p>Manage your trusted network, including managing network invitations and sharing preferences.</p></>,
							placement:"right-start"
						},
						{
							target: ".alerts-navigation",
							content: <><h2>Alerts Navigation</h2><p>Use alerts to identify and manage compliance and risk concerns in your network.</p></>,
							placement:"right-start"
						},
						{
							target: ".business-profile",
							content: <><h2>Personal &amp; Business Profile</h2><p>Keep your business contact information up-to-date, so they have accurate infomation about you in their network.</p></>,
							placement:"bottom-start"
						},
						{
							target: ".profile-image-frame",
							content: <><h2>Profile Image</h2><p>Always present a professional image by uploading a profile image that clients can see in their network search results</p></>,
							placement:"left-start"
						},
						{
							target: ".password-security",
							content: <><h2>Passwords &amp; Security</h2><p>Use these options to update your password and your two-factor authentication. Pro Tip: Text Authentication is always faster then emails.</p></>,
							placement:"top-start"
						},
						{
							target: ".privacy-preferences",
							content: <><h2>Privacy &amp; Personal Data</h2><p>Manage how people can access your background check data.</p></>,
							placement:"top-start"
						},
						{
							target: ".purchase-history",
							content: <><h2>Purchases</h2><p>Your background check purchase history, including the ability to download reports and receipts.</p></>,
							placement:"top-start"
						},
						{
							target: ".my-network",
							content: <><h2>My Network</h2><p>Your background check purchase history, including the ability to download reports and receipts.</p></>,
							placement:"auto"
						},
						{
							target: ".network-actions",
							content: <><h2>Network Action Menu</h2><p>Use to send an invitation to join your network or share your ShieldScore with others.</p></>,
							placement:"bottom"
						},
						{
							target: ".sharedwithme",
							content: <><h2>Shared with Me Tab</h2><p>These are users that are actively sharing their ShieldSCore with you. From here you can manage contractors and their status in your trusted network.</p></>,
							placement:"bottom"
						},
						{
							target: ".sharedwithothers",
							content: <><h2>Shared with Others</h2><p>These are clients and users that you are sharing your Background check data. Manage your sharing settting with your network partners.</p></>,
							placement:"bottom"
						},
						{
							target: ".alerts-date-range",
							content: <><h2>Alerts Date Range</h2><p>Adjust the date range of your alerts to expand or narrtow your search. Alerts date range defaults to the last month.</p></>,
							placement:"bottom"
						},
						{
							target: ".alerts-subscribe",
							content: <><h2>Alerts News Subscription</h2><p>Subscribe to alerts and we&apos;ll send you network changes to your mailbox daily.</p></>,
							placement:"bottom"
						},
						{
							target: ".alerts-complianceguard",
							content: <><h2>ComplianceGuard™</h2><p>Use compliacne guard to highlight ShieldScore and other status compliance issues that may require attention.</p></>,
							placement:"bottom"
						},
						{
							target: ".alerts-settings",
							content: <><h2>Alerts Settings</h2><p>Set which statuses and alerts you wish to track. Reduce network noise by only tracking necesdsary events.</p></>,
							placement:"bottom"
						},
						{
							target: ".alerts-dashboard",
							content: <><h2>Alerts Dashboard</h2><p>Track key alert and performance metrics accross your entire network. Responsive to changes in the Alerts Date Range.</p></>,
							placement:"auto"
						},
						{
							target: ".alerts-feed",
							content: <><h2>Network Activity Feed</h2><p>Continually tracks changes and alerts accrooss your network.  Use the network feed to identify compliance risk and concerns in your network.</p></>,
							placement:"auto"
						},
						{
							target: ".alerts-search",
							content: <><h2>Alerts Search</h2><p>Search your activity to discover risks in your current network.</p></>,
							placement:"auto"
						},
					]}
					styles={{
						options: {
							primaryColor: "#BE2026",
							fontSize:"10px",
						},
					}}
				/>
			</div>
		</>
	);

}

export default User