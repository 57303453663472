import * as React from "react";
import { Col, Row , FloatingLabel , Form , Button , Collapse, Card, Spinner} from "react-bootstrap";
import { ChevronDown, Pencil, ChevronRight } from "react-bootstrap-icons";
import statelist from '../../../io/states';
import api from "../../../io/profile";
import DatePicker from "react-datepicker";
import { format as dateFormat , parse as dateParse , parseISO as dateParseISO , isValid as dateValid } from 'date-fns';
import useSessionStorage from '@twipped/hooks/useSessionStorage';
import "react-datepicker/dist/react-datepicker.css";
import ICScore from "../../ICScore";
import { useAuth } from "../../Auth";

function Profile( props )
{

	const [ sessionProfile , setSessionProfile ] = useSessionStorage('ShieldHubProfile',{});
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ profile , setProfile ] = React.useState(sessionProfile);
	const [ uploadinprogress , setUploadinprogress ] = React.useState(false);
	const auth = useAuth();

	// const [ ssnfocus , setSSnFocus ] = React.useState(false);

	const formref = React.createRef();
	const uploadInputRef = React.createRef();

	function handleStateSelectChange( event )
	{
		let newprofile = { ...profile };

		newprofile.state = event.currentTarget.value;

		setProfile(newprofile);

	}
	function handleDOBChange( date )
	{
		let newprofile = { ...profile };

		newprofile.dateofbirth = date;

		setProfile(newprofile);
	}

	async function GetProfileDetails()
	{
		let response = await api.getmyprofile();

		if(response.success)
		{
			if(response.profile.mobile && response.profile.mobile.length > 0)
			{
				response.profile.mobile = response.profile.mobile.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');
			}
			if(response.profile.businessphone && response.profile.businessphone.length > 0)
			{
				response.profile.businessphone = response.profile.businessphone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');
			}
			if(response.profile.ssn && response.profile.ssn.length)
			{
				response.profile.maskedssn = "●●●-●●●-●●●●";
			}
			if(response.profile.dateofbirth && typeof response.profile.dateofbirth === 'string' && response.profile.dateofbirth.length > 0)
			{
				try{
					response.profile.dateofbirth = dateParseISO(response.profile.dateofbirth);
				}
				catch(e)
				{
					console.warn(e);
					response.profile.dateofbirth = null;
				}
			}
			else if(response.profile.dateofbirth && response.profile.dateofbirth.length > 0)
			{
				try{
					response.profile.dateofbirth = dateParse(response.profile.dateofbirth,"yyyy-MM-dd",new Date());
				}
				catch(e)
				{
					console.warn(e);
					response.profile.dateofbirth = null;
				}
			}

			if(response.profile.current_expiration && response.profile.current_expiration.length > 0)
			{
				try{
					response.profile.current_expiration = dateParse(response.profile.current_expiration,"yyyy-MM-dd",new Date());
				}
				catch(e)
				{
					console.warn(e);
					response.profile.current_expiration = null;
				}
			}
	

			setProfile(response.profile);
		}
	}

	async function SaveProfile()
	{
		props.SaveEvent('ProfileInfo','startsave');

		let profileobject = {};

		let formData = new FormData(formref.current);

		profileobject.lastname = formData.get('lastname');
		profileobject.firstname = formData.get('firstname');
		profileobject.mobile = formData.get('mobile');
		profileobject.emailaddress = formData.get('emailaddress');
		profileobject.businessname = formData.get('businessname');
		profileobject.address1 = formData.get('address1');
		profileobject.address2 = formData.get('address2');
		profileobject.city = formData.get('city');
		profileobject.state = formData.get('state');
		profileobject.postalcode = formData.get('postalcode');

		//get SSN from profile state object because the form field will be masked
		if( profile.ssn !== undefined && profile.ssn !== null )
		{
			profileobject.ssn = profile.ssn;
		}

		if(formData.get('dateofbirth') && formData.get('dateofbirth').length > 0)
		{
			profileobject.dateofbirth = formData.get('dateofbirth');
		}
		else if(profile?.dateofbirth?.length > 0)
		{
			profileobject.dateofbirth = profile.dateofbirth;
		}
		else
		{
			profileobject.dateofbirth = '';
		}
		profileobject.businessphone = formData.get('businessphone');

		profileobject.mobile = profileobject.mobile.replace(/[^0-9]/g,'');

		if(profileobject.dateofbirth && profileobject.dateofbirth.length > 0 && profileobject.dateofbirth.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/))
		{
			var dateofbirth = dateParse(profileobject.dateofbirth,"MM/dd/yyyy",new Date());
			profileobject.dateofbirth = dateFormat(dateofbirth,"yyyy-MM-dd");
		}

		await api.saveprofileinfo(profileobject);

		props.SaveEvent('ProfileInfo','endsave');

		//update session profile object as well
		let new_session_profile = {...sessionProfile};
		new_session_profile.lastname = profileobject.lastname;
		new_session_profile.firstname = profileobject.firstname;
		new_session_profile.mobile = profileobject.mobile;
		new_session_profile.emailaddress = profileobject.emailaddress;
		new_session_profile.businessname = profileobject.businessname;
		new_session_profile.address1 = profileobject.address1;
		new_session_profile.address2 = profileobject.address2;
		new_session_profile.city = profileobject.city;
		new_session_profile.state = profileobject.state;
		new_session_profile.postalcode = profileobject.postalcode;
		new_session_profile.dateofbirth = profileobject.dateofbirth;
		new_session_profile.businessphone = profileobject.businessphone;
		setSessionProfile(new_session_profile);

	}

	async function handleFormBlur()
	{
		await SaveProfile();
	}

	async function handleProfileImageUpload( event )
	{
		props.SaveEvent('ProfileInfo','startsave');

		setUploadinprogress(true);

		let getposturl = await api.getprofileimageurl();

		let freader = new FileReader();
		try{
			freader.readAsArrayBuffer(event.target.files[0]);

			freader.onload = async () => {
				let response = await api.uploadprofileimagetos3(getposturl.posturl,freader.result);

				if(response.success)
				{
					let profileimage_response = await api.uploadprofileimage('success');

					if(profileimage_response.profileimage)
					{
						let newprofile = { ...profile };

						newprofile.profileimage = profileimage_response.profileimage;

						setProfile(newprofile);
					}
				}
				setUploadinprogress(false);

				props.SaveEvent('ProfileInfo','endsave');
			}
			freader.onerror = (err) => {
				setUploadinprogress(false);
				console.warn(err);
			}
		}
		catch (e)
		{
			setUploadinprogress(false);
			console.warn(e);
		}

	}

	function formatPhoneNumber(event , fieldname )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newprofile = { ...profile };

		if(newprofile[fieldname] === undefined || newprofile[fieldname] === null)
		{
			newprofile[fieldname] = '';
		}

		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newprofile[fieldname] && newprofile[fieldname].length > 0)
			{
				newprofile[fieldname] = newprofile[fieldname].substring(0,newprofile[fieldname].length-1);

				setProfile(newprofile);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(!event.key.match(/[0-9]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile[fieldname] += event.key;

		if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}$/))
		{
			newprofile[fieldname] += "-";
		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}-[0-9]{3}$/))
		{
			newprofile[fieldname] += "-";
		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
		{

		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
		{

		}
		else
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		setProfile(newprofile);
	}

	/*
	function formatSSN( event )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newprofile = { ...profile };

		if(newprofile.ssn === undefined || newprofile.ssn === null)
		{
			newprofile.ssn = '';
		}

		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newprofile.ssn && newprofile.ssn.length > 0)
			{
				newprofile.ssn = newprofile.ssn.substring(0,newprofile.ssn.length-1);

				setProfile(newprofile);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(!event.key.match(/[0-9]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile.ssn += event.key;

		if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{3}$/))
		{
			newprofile.ssn += "-";
		}
		else if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{3}-[0-9]{2}$/))
		{
			newprofile.ssn += "-";
		}
		else if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
		{
			
		}
		else if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
		{

		}
		else
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile.maskedssn = "●●●-●●●-●●●●";

		setProfile(newprofile);		
	}
	*/

	React.useEffect( () => {

		( async () => {
			await GetProfileDetails();
		})();

	},[]);

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {
		
		( async () => {
			await SaveProfile();
		})();
	}, [ profile.ssn ]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (

		<Card className="p-4 d-inline-block business-profile">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Personal &amp; Business Profile</Button>


			<Collapse in={panelshow}>
				<Form ref={formref} autoComplete="off">
					<Row className="d-flex">
						<Col lg={{span:9,order:1}} xs={{span:12,order:2}} className="profileinfo-container">
							<Row>
								<Col sm={12} lg={6} className="mb-2" >
									<FloatingLabel controlId="firstname" label="FIRST NAME">
										<Form.Control 
											type="text" 
											placeholder="FIRST NAME" 
											name="firstname"
											defaultValue={profile.firstname}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={12} lg={6} className="mb-2">
									<FloatingLabel controlId="lastname" label="LAST NAME">
										<Form.Control 
											type="text" 
											placeholder="LAST NAME" 
											name="lastname"
											defaultValue={profile.lastname}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={12} xs={12} className="mb-2">
									<FloatingLabel controlId="businessname" label="BUSINESS NAME">
										<Form.Control 
											type="text" 
											placeholder="BUSINESS NAME" 
											name="businessname"
											defaultValue={profile.businessname}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
					
								<Col sm={12} className="mb-2">
									<FloatingLabel controlId="address1" label="ADDRESS 1">
										<Form.Control 
											type="text" 
											placeholder="ADDRESS 1" 
											name="address1"
											defaultValue={profile.address1}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={12} className="mb-2">
									<FloatingLabel controlId="address2" label="ADDRESS 2">
										<Form.Control 
											type="text" 
											placeholder="ADDRESS 2" 
											name="address2"
											defaultValue={profile.address2}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={12} className="mb-2">
									<FloatingLabel controlId="city" label="CITY">
										<Form.Control 
											type="text" 
											placeholder="CITY" 
											name="city"
											defaultValue={profile.city}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={6} className="mb-2">
									<FloatingLabel className="statedropdownprofile" controlId="state" label="STATE">
										<Form.Select
											placeholder="STATE" 
											name="state"
											size="lg"
											onBlur={handleFormBlur}
											value={profile.state}
											style={{paddingRight:"0px"}}
											onChange={handleStateSelectChange}
										>
											<option></option>
											{Object.keys(statelist).map( (abbr) => {
												return <option key={abbr} value={abbr}>{abbr}</option>;
											} )}
									
										</Form.Select>
									</FloatingLabel>
								</Col>
		
								<Col sm={6} className="mb-2">
									<FloatingLabel controlId="postalcode" label="POSTAL CODE">
										<Form.Control 
											type="text" 
											placeholder="POSTAL CODE" 
											name="postalcode"
											defaultValue={profile.postalcode}
											maxLength={5}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={6} className="mb-2">
									<FloatingLabel controlId="mobile" label="MOBILE PHONE NUMBER">
										<Form.Control 
											type="text" 
											placeholder="MOBILE PHONE NUMBER" 
											name="mobile"
											value={profile.mobile ? profile.mobile : ''}
											onBlur={handleFormBlur}
											onKeyDown={(event) => { formatPhoneNumber(event,'mobile') }}
											onChange={() => {

											}}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={6} className="mb-2">
									<FloatingLabel controlId="businessphone" label="BUSINESS PHONE NUMBER">
										<Form.Control 
											type="text" 
											placeholder="BUSINESS PHONE NUMBER" 
											name="businessphone"
											value={profile.businessphone ? profile.businessphone : ''}
											onKeyDown={(event) => { formatPhoneNumber(event,'businessphone') }}
											onBlur={handleFormBlur}
											onChange={() => {

											}}
										/>
									</FloatingLabel>						
								</Col>

								{/* changing from 12 to 8 while SSN is hidden until MFA can be enabled */}
								<Col md={8} className="mb-2">
									<FloatingLabel controlId="emailaddress" label="EMAIL ADDRESS">
										<Form.Control 
											type="text" 
											placeholder="EMAIL ADDRESS" 
											name="emailaddress"
											defaultValue={profile.emailaddress}
											onBlur={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								{/* <Col sm={6} className="mb-2">
									<FloatingLabel controlId="ssn" label="SOCIAL SECURITY NUMBER">
										<Form.Control 
											type="text" 
											placeholder="SOCIAL SECURITY NUMBER" 
											name="ssn"
											value={ssnfocus ? (profile.ssn ? profile.ssn : '') : ( profile.maskedssn ? profile.maskedssn : '') }
											onKeyDown={formatSSN}
											onBlur={() => {
												setSSnFocus(false);
											}}
											onFocus={() => {
												setSSnFocus(true);
											}}
											onChange={() => {

											}}
										/>
									</FloatingLabel>
								</Col> */}
								{/* changing from 6 to 4 while SSN is hidden until MFA can be enabled */}
								<Col sm={4} className="mb-2">
									<DatePicker 
										selected={(profile.dateofbirth && dateValid(profile.dateofbirth) ? profile.dateofbirth : null )} 
										onSelect={handleDOBChange}
										onChange={handleFormBlur}
										onBlur={handleFormBlur}
										showYearDropdown
										yearDropdownItemNumber={100}
										scrollableYearDropdown
										filterDate={(date) => {
											const currentDate = new Date();
											const min_date = new Date('1900-01-01T00:00:00');
											return date < currentDate && date > min_date ;
										}}
										customInput={<FloatingLabel controlId="dateofbirth" label="DATE OF BIRTH"><Form.Control type="text" placeholder="DATE OF BIRTH" name="dateofbirth" defaultValue={ (profile.dateofbirth && dateValid(profile.dateofbirth) ? dateFormat(profile.dateofbirth,"MM/dd/yyyy") : null )} /></FloatingLabel>}
										popperClassName="react-datepicker-popper-zindex"
									/>
								</Col>

							</Row>
						</Col>
						


						<Col lg={{span:3,order:2}} sm={{span:12,order:1}}  style={{textAlign:"center"}}>
							<input ref={uploadInputRef} type="file" name="profileimage" onChange={handleProfileImageUpload} style={{display:"none"}} />
							<div className="profile-image-container">
								<div style={{zIndex:"1",position:"relative",color:"#2A2D40"}}>
									{profile?.profileimage?.length > 0 && uploadinprogress === false && <div className="profile-image-frame" style={{backgroundImage:'url("'+profile.profileimage+'")'}} /> }
									{ !(profile?.profileimage) && <div className="profile-image-frame" style={{backgroundImage:'url("/images/user-icon.png")'}} /> }
								</div>
								<div>
									{uploadinprogress === true && <Spinner animation="border" style={{marginTop:'-12px',marginLeft:'20px'}}/>}
									{uploadinprogress !== true && 
									<Button className="edit_profile_button" style={{borderRadius:"20px",width:"38px"}} onClick={() => {
										uploadInputRef.current.click();
									}}>
										<Pencil style={{marginTop:"-2px",marginLeft:"-2px"}}/>
									</Button>
									}
								</div>
								<Row className="p-2"><Col className='scorecolumn'>{profile.currenticstatus && <ICScore score={profile.currenticstatus} />}</Col></Row>
								<Row className="p-2"><Col style={{textAlign:'center'}}>{auth.user.certification_number && ('ShieldID: '+auth.user.certification_number)}</Col></Row>
								<Row className="p-2"><Col style={{textAlign:'center'}}>Expires: {(profile.current_expiration && dateValid(profile.current_expiration) ? dateFormat(profile.current_expiration,"MM/dd/yyyy") : null )}</Col></Row>
							</div>
						</Col>
		
					</Row>
				</Form>
			</Collapse>
		</Card>

	);
}

export default Profile;